img {
  width: 200px;
  height: 200px;
  margin-bottom: 30px;
}

.startScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.startScreen h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.startScreen button {
  padding: 10px 20px;
  font-size: 1.2rem;
  background-color: #f8b400;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 5px;
}

.startScreen button:hover {
  background-color: #ffd04f;
}

.horizontalButtonsContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.horizontalButtonsContainer button {
  font-size: 1rem;
  margin-left: 5px;
  margin-right: 5px;
}

.startScreen select {
  text-align: center;
  padding: 10px 20px;
  font-size: 1.2rem;
  background-color: #f8b400;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
}

.startScreen select:hover {
  background-color: #ffd04f;
}