/* Reset default styles */
* {
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}

h1 {
  font-size: 28px;
  text-align: center;
  margin-top: 20px;
}

.game-row {
  display: flex;
  flex-wrap: wrap; /* Allow game boards to wrap onto new rows */
  justify-content: center;
  margin-top: 5px;
}

.game-board {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  border: 2px solid #333;
  border-radius: 8px;
  overflow: hidden;
  width: calc(
    33.33% - 20px
  ); /* Ensure each board takes up 1/3 of the container width */
  max-width: 300px; /* Limit maximum width of each board */
}

.game {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.github-link {
  background: url(../media/github-mark.svg) no-repeat;
  cursor: pointer;
  border: none;
  width: 40px;
  height: 40px;
  background-size: contain;
  position: absolute;
  top: 40px;
  right: 55px;
}

.sound-on {
  background: url(../media/sound-on.png) no-repeat;
  cursor: pointer;
  border: none;
  width: 40px;
  height: 40px;
  background-size: contain;
  position: absolute;
  top: 40px;
  left: 10px;
}

.sound-off {
  background: url(../media/sound-off.png) no-repeat;
  cursor: pointer;
  border: none;
  width: 40px;
  height: 40px;
  background-size: contain;
  position: absolute;
  top: 40px;
  left: 10px;
}

.home-button {
  background: url(../media/home.png) no-repeat;
  cursor: pointer;
  border: none;
  width: 43px;
  height: 43px;
  background-size: contain;
  position: absolute;
  top: 40px;
  left: 55px;
}

.online-info {
  font-size: 13px;
}
