li {
  margin: 10px 0;
}

.about {
  position: absolute;
  top: 40px;
  right: 5px;
  padding: 10px;
  background-color: #f8b400;
  color: #fff;
  border: none;
  width: 41px;
  height: 41px;
  border-radius: 50%;
  font-size: 20px;
  color: black;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.25s ease;
  z-index: 999; /* Ensure it's above other elements */
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutInactive {
  position: absolute;
  top: 40px;
  right: 5px;
  padding: 10px;
  background-color: #f8b400;
  color: #fff;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 20px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.25s ease;
  z-index: 0; /* Ensure it's below other elements */
  display: flex;
  justify-content: center;
  align-items: center;
}

.about:hover {
  background-color: #ffd04f;
}

.aboutWindow {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 90vw;
  max-width: 400px;
  border-radius: 10px;
  padding: 20px;
  text-align: left;
  color: black;
  font-size: 16px;
  z-index: 998;
  max-height: 80vh;
}

.aboutWindowContent {
  padding-top: 40px;
}

.closeWindowButton {
  position: absolute;
  top: 10px; /* Positioned at the bottom */
  right: 10px;
  background-color: #f8b400;
  color: white;
  font-size: 15px;
  border: 0;
  padding: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.25s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeWindowButton:hover {
  background-color: #ffd04f;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjusted for better visibility */
  z-index: 997; /* Ensure it's below other elements */
}
