.square {
  background-color: #fff;
  border: 2px solid #333;
  font-size: 24px;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  width: 40px;
  height: 40px;
}

.activeSquare {
  background-color: #f8b400;
  border: 2px solid #333;
  font-size: 24px;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  width: 40px;
  height: 40px;
}

.activeSquare:hover {
  background-color: #b5880c;
}
