.boardRow {
  display: flex;
}

.status {
  font-size: 15px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 20px;
  text-align: center;
  height: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
}
